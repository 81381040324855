import React from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import HeroImage from '../components/HeroImage'
import { Helmet } from 'react-helmet';
import LLMsWork from '../components/LLMsWork'

const HowLLMsWork = () => {
  return (
    <div>
      <Helmet>
    <title>OpenAFI | How OpenAFI Works</title>
      </Helmet>
        <Navbar/>
        <HeroImage heading='How OpenAFI Work' text='How the AI goes into AFI'/>
        <LLMsWork />
        <Footer />
    </div>
  )
}

export default HowLLMsWork