import React from 'react'
import { Link } from 'react-router-dom'
import './FooterStyles.css'

function Footer() {
  return (
    <div className='footer'>
      <div className='footer-container'>
        <p>AJ Parker Scott Engineering</p>
      </div>
    </div>
  )
}

export default Footer