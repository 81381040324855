import React from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import HeroImage from '../components/HeroImage'
import Information from '../components/Info'
import { Helmet } from 'react-helmet';

const Info = () => {
  return (
    <div>
      <Helmet>
    <title>OpenAFI | Info</title>
      </Helmet>
        <Navbar/>
        <HeroImage heading='INFO' text='Information on this project'/>
        <Information />
        <Footer/>
    </div>
  )
}

export default Info