import React from 'react'
import { Link } from 'react-router-dom'
import sadant from '../assets/sadant.jpg'
import cashaspp from '../assets/cashapp.jpg'

function Support() {
  return (
    <div className='information'>
      <div className='left'> 
        <h1>Support This Project</h1>
        <p>OpenAFI is a powerful tool, fine-tuned on the most recent AFI publications and backed by the entire AFI public library stored in an online knowledge base. However, maintaining and improving this system comes with significant costs.</p>
        <p>Each month, there are expenses to fine tune the LLM, continuously maintain access to several servers, and ensure the usability of OpenAFI. Despite these efforts, the project is not yet self-sufficient. Currently, all of OpenAFI is funded by it's creator.</p>
        <p>To maintain 100% E-Pub Doc AI training completion and ensure OpenAFI remains a reliable resource, additional server space is needed. Whenever new AFI publications are released, the model needs to be re-fine-tuned, and the vector database restructured.</p>
        <p>If you appreciate the value OpenAFI provides and enjoy using this free product, your support is greatly appreciated. A donation, or simply sharing OpenAFI with fellow airmen, can make a significant difference. Every contribution helps move closer to the goal.</p>
        <p>Thank you for your support!</p>
        
        <a href="https://www.paypal.com/donate/?hosted_button_id=8CV3K24H3YFY2" className='btn' target="_blank" rel="noopener noreferrer">Paypal</a>
      </div>
      <div className='right'>
        <img src={cashaspp} className='img' alt='' />
      </div>
    </div>
  )
}

export default Support
