import React from 'react'
import {Routes, Route} from 'react-router-dom'
import Home from './routes/home'
import Fetch from './routes/Fetch'
import Info from './routes/Info'
import Contact from './routes/Contact'
import Help from './routes/Support'
import MakingOfOpenAFI from './routes/MakingOfOpenAFI'
import HowLLMsWork from './routes/HowLLMsWork'
import Privacy from './routes/Privacy'

function App() {
  return (
    <>
    <title>OpenAFI</title>
      <Routes>
        <Route path='/' element={<Home />}/>
        <Route path='/Fetch' element={<Fetch />}/>
        <Route path='/Info' element={<Info />}/>
        <Route path='/Contact' element={<Contact/>}/>
        <Route path='/Support' element={<Help/>}/>
        <Route path='/MakingOfOpenAFI' element={<MakingOfOpenAFI/>}/>
        <Route path='/HowLLMsWork' element={<HowLLMsWork/>}/>
        <Route path='/Privacy' element={<Privacy/>}/>
      </Routes>
    </>
  );
}

export default App;
