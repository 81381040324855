import React from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import Chatbot from '../components/Chatbot'
import HeroImage from '../components/HeroImage'
import { Helmet } from 'react-helmet';

function Fetch() {
  return (
    <div>
        <Navbar />
        <HeroImage heading='Chatbot' text='letting the AFIs speak for themselves' />
        <Chatbot/>
        <Footer />
    </div>
  )
}

export default Fetch