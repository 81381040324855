import React from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import HeroImage from '../components/HeroImage'
import { Helmet } from 'react-helmet';
import Support from '../components/Support';

const Help = () => {
  return (
    <div>
      <Helmet>
    <title>OpenAFI | Support</title>
      </Helmet>
        <Navbar/>
        <HeroImage heading='Support' text='Your help is greatly appreciated'/>
        <Support />
        <Footer/>
    </div>
  )
}

export default Help