import React from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import HeroImage from '../components/HeroImage'
import MakingOf from '../components/MakingOf'
import { Helmet } from 'react-helmet';

const MakingOfOpenAFI = () => {
  return (
    <div>
      <Helmet>
    <title>OpenAFI | Making Of OpenAFI</title>
      </Helmet>
        <Navbar/>
        <HeroImage heading='Making of OpenAFI' text='Behind the ideas and future plans'/>
        <MakingOf />
        <Footer/>
    </div>
  )
}

export default MakingOfOpenAFI