import React from 'react';
import { Link } from 'react-router-dom';
import privacy from '../assets/privacy.jpg';
import cashapp from '../assets/cashapp.jpg';

function PrivacyPolicy() {
  return (
    <div className='information'>
      <div className='left'> 
        <h1>Privacy Policy</h1>
        <p>Effective Date: 30th June 2024</p>

        <p>At Open AFI, your privacy is taken seriously, and your personal information is protected. This Privacy Policy Statement explains how data is collected, used, disclosed, and safeguarded when you visit the website. By using the website, you consent to the practices outlined in this policy.</p>

        <p>1. Information Collected:</p>

        <p>a. Data Collection: Data is collected to see what themes are being used, what questions are being asked, and how the service is being used. Personal data is not stored, nor is there tracking of who is asking what questions.</p>

        <p>b. Non-Personal Information: Non-personal information may be collected automatically, such as your IP address, browser type, device information, operating system, and website usage data, through cookies, web beacons, and similar technologies. This information enhances your browsing experience and helps understand how visitors interact with the website.</p>

        <p>2. Use of Collected Information:</p>

        <p>a. Data Usage: The collected information is used to analyze website traffic patterns, optimize content, and improve user experience. It is used in an aggregated and anonymous form for statistical purposes.</p>

        <p>3. Cookies and Similar Technologies:</p>

        <p>Cookies, web beacons, and other tracking technologies may be used to collect non-personal information. Cookies are small data files stored on your device that improve your browsing experience. You can manage your cookie preferences through your browser settings.</p>

        <p>4. Third-Party Links:</p>

        <p>The website may contain links to external websites operated by third parties. Please note that Open AFI has no control over the content and practices of these websites. This Privacy Policy does not cover the practices of these third-party websites. It is recommended to review their respective privacy policies.</p>

        <p>5. Data Security:</p>

        <p>Reasonable security measures are implemented to protect your information from unauthorized access, disclosure, alteration, or destruction. However, please be aware that no method of transmission over the internet or electronic storage is entirely secure, and absolute security cannot be guaranteed.</p>

        <p>6. Changes to this Privacy Policy:</p>

        <p>This Privacy Policy may be updated periodically to reflect changes in practices or for other operational, legal, or regulatory reasons. Any updates will be effective upon posting the revised policy on this page. It is encouraged to review this Privacy Policy regularly for the latest information on data practices.</p>

        <p>7. Contact:</p>

        <p>If you have any questions, concerns, or requests regarding this Privacy Policy or the handling of your information, please contact Open AFI.</p>

        <p>By continuing to use the website, you acknowledge that you have read and understood this Privacy Policy and consent to the collection and use of your information as described herein.</p>

        <p>Open AFI</p>
      </div>
      <div className='right'>
        <img src={privacy} className='img' alt='Privacy' />
      </div>
    </div>
  );
}

export default PrivacyPolicy;
