import React from 'react'
import './ChatbotStyles.css'
import {Link} from 'react-router-dom'

const Chatbot = () =>{
    return (
        <div className='Fetch'>
           
        </div>
    )
}

export default Chatbot