import React from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import HeroImage from '../components/HeroImage'
import { Helmet } from 'react-helmet';
import PrivacyPolicy from '../components/PrivacyPolicy'

const Privacy = () => {
  return (
    <div>
      <Helmet>
    <title>OpenAFI | Privacy</title>
      </Helmet>
        <Navbar/>
        <HeroImage heading='Privacy' text='OpenAFIs Privacy Policy'/>
        <PrivacyPolicy />
        <Footer/>
    </div>
  )
}

export default Privacy