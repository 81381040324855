import React from 'react'
import Navbar from '../components/Navbar'
import Video from '../components/Video'
import Footer from '../components/Footer'
import { Helmet } from 'react-helmet';

const Home = () => {
  return (
    <div>
   <Helmet>
    <title>OpenAFI</title>
      </Helmet>
        <Navbar />
        <Video />
        <Footer />
    </div>
  )
}

export default Home