import React from 'react'
import './InfoStyles.css'
import './CardStyles.css'
import {Link} from 'react-router-dom'
import A1C_Carmichael from '../assets/A1C_Carmichael.jpg'
import useravi from '../assets/useravi.jpg'
import { Helmet } from 'react-helmet';



const Information = () => {
  return (
<div className='Fetch'>
          <div className='card-container'>
              <div className='card'>
                  <h3>- The Making of OpenAFI -</h3>
                  <p>The ideas behind the project, alongside future plans</p>
                  <span className='bar'></span>
                  <Link to='/MakingOfOpenAFI' className='btn'>Read</Link>
              </div>
              <div className='card'>
                  <h3>- How OpenAFI Works -</h3>
                  <p>Using Large Language Models as personal assistants in a convenient way</p>
                  <span className='bar'></span>
                  <Link to='/HowLLMsWork' className='btn'>Read</Link>
              </div>
              <div className='card'>
                  <h3>- Privacy -</h3>
                  <p>Information on your privacy rights whilst accessing this website</p>
                  <span className='bar'></span>
                  <Link to='/Privacy' className='btn'>Read</Link>
              </div>
          </div>
          </div>

  )
}

export default Information