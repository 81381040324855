import React from 'react'
import HeroImage from '../components/HeroImage'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import Form from '../components/Form'
import { Helmet } from 'react-helmet';

const Contact = () => {
  return (
    <div>
        <Navbar/>
        <HeroImage heading='Contact' text='Your feedback is greatly appreciated'/>
        <Form/>
        <Footer/>
    </div>
  )
}

export default Contact 