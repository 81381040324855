import React from 'react'
import { Link } from 'react-router-dom'
import A1C_Carmichael from '../assets/A1C_Carmichael.jpg'
import useravi from '../assets/useravi.jpg'

function MakingOf() {
  return (
    <div className='information'>
    <div className='left'> 
        <h1>Making of OpenAFI</h1>
        <p>OpenAFI is a collaboration project between TSGt Scott and SrA Carmichael. The idea behind an LLM dedicated towards AFI was given by TSGt Scott to SrA Carmichael who was already working on an LLM dedicated to TOs.</p>
        <p>The TO specific variation is still in works, however, the implementation for that is targetting field level usage where internet connectivity is unavailable. Local LLMs are far more intense and require more powerful computing, however, with each technologoical advancement, the project nears completion.</p>
        <p>Offline LLMs that are local to one device also don't run the risk of data leakage through the use of external servers.</p>
        <p>For that reason alone, only publicly available e-pubs documents will be available using our model.</p>
        <p>If you would like to know more about how LLMs work, feel free to read this article on it.</p>
        <Link to='/HowLLMsWork'><button className='btn'>How OpenAFI Works</button></Link>
    </div>
    <div className='right'>
        <div className='img-container'>
            <div className='image-stack top'>
                <img src={A1C_Carmichael} className='img' alt='A1C Carmichael' />
            </div>
            <div className='image-stack bottom'>
                <img src={useravi} className='img' alt='TSGt Scott' />
            </div>
        </div>
    </div>
</div>
  )
}

export default MakingOf